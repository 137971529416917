@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  background-color: lightgrey;
  font-family: 'Signika', sans-serif;
  font-family: 'Comfortaa', cursive;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 16px;
  margin: 0;
}

.container {
  position: relative;
  top: 110px;
  /* max-width: 900px; */
  margin: 0.5rem 2rem;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: white;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  padding: 0;
  width: 100%;
}

.form-group {
  margin: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.form-group > label {
  color: #777;
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
}

.btn {
  background-color: hsl(200, 100%, 50%);
  color: white;
  padding: 0.5em 1em;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: hsl(200, 100%, 30%);
}

.card-grid {
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 150ms;
  cursor: pointer;
  -webkit-transform: perspective(1000px) rotateY(0)
    translateY(0);
          transform: perspective(1000px) rotateY(0)
    translateY(0);
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
          transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}
.card:hover {
  --translate-y: 2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
}
.card.flip {
  --rotate-y: 180deg;
}
.card .front {
  left: 0;
}
.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.card .back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.flashcard-options {
  margin-top: 0.5rem;
}
.flashcard-option {
  margin-top: 0.25rem;
  color: #555;
  font-size: 0.75rem;
}
.flashcard-option:first-child {
  margin-top: 0;
}

footer {
  min-height: 57px;
  background-color: white;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

